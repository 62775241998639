import { format, addDays, isBefore } from "date-fns";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import WelcomeContainer from "@/components/WelcomeContainer";
import {
  Typography,
  LinearProgress,
  Grid,
  Button,
  Chip,
  Card,
  CardContent,
} from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import SuccessCheckWhiteIcon from "@/assets/images/success_check_white.svg";
import SuccessCheckIcon from "@/assets/images/success_check.svg";
import FixFormIcon from "@/assets/images/fix_form.svg";
import CallIcon from "@/assets/images/call.svg";
import CalendarIcon from "@/assets/images/calendar.svg";
import EnvelopeIcon from "@/assets/images/envelope.svg";
import { useCompletedSections } from "./useCompletedSections";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import {
  ResponseForm,
  ResponseFormStatus,
} from "@/services/registration/types";
import { useMemo } from "react";
import { BsExclamationCircle } from "react-icons/bs";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    progress: {
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: Tokens.feedbackColor.success.pure,
      },
    },
  }),
);

interface Props {
  loading: boolean;
  responseForm?: ResponseForm;
  error: string | null;
  onFillForm: () => void;
}

export default function AdmittanceDash({
  loading,
  responseForm,
  error,
  onFillForm,
}: Props) {
  const classes = useStyles();

  const { completedItems, percentComplete, isComplete } = useCompletedSections(
    responseForm?.sections,
  );

  const isFormStatusNotStarted =
    responseForm?.status === ResponseFormStatus.NOT_STARTED;
  const isFormStatusInProgress =
    responseForm?.status === ResponseFormStatus.FORM_IN_PROGRESS;
  const isFormStatusAwaitingApproval =
    responseForm?.status === ResponseFormStatus.AWAITING_APPROVAL;
  const isFormStatusFinished =
    responseForm?.status === ResponseFormStatus.FINISHED;
  const isApproximateDate = useMemo(() => {
    const today = new Date();
    const addmitanceDate = new Date(responseForm?.admittanceDate as string);
    const sevenDaysFromToday = addDays(today, 7);
    return isBefore(addmitanceDate, sevenDaysFromToday);
  }, [responseForm?.admittanceDate]);
  const isFixForm = Boolean(responseForm?.fixMessage);

  if (error && error === "registration fill period ended") {
    return (
      <div className={classes.root}>
        <WelcomeContainer style={{ alignItems: "center" }}>
          <Alert
            variant="filled"
            severity="info"
            style={{ backgroundColor: Tokens.feedbackColor.error.light }}
            icon={
              <BsExclamationCircle color={Tokens.feedbackColor.error.dark} />
            }
          >
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: Tokens.feedbackColor.error.dark,
              }}
            >
              Data de entrada expirada
            </Typography>
            <div style={{ marginTop: 5 }} />
            <Typography
              variant="body2"
              style={{ fontSize: 14, color: Tokens.feedbackColor.error.dark }}
            >
              O formulário de admissão não foi enviado até a data de entrada.
              Para que o formulário possa ser preenchido, é necessário que a
              pessoa responsável pelo processo de admissão altere a data de
              entrada.
            </Typography>
          </Alert>
        </WelcomeContainer>
      </div>
    );
  }
  if (
    error &&
    (error === "Token invalid" || error === "registration not found")
  ) {
    return (
      <div className={classes.root}>
        <WelcomeContainer style={{ alignItems: "center" }}>
          <Alert
            variant="filled"
            severity="info"
            style={{ backgroundColor: Tokens.feedbackColor.error.light }}
            icon={
              <BsExclamationCircle color={Tokens.feedbackColor.error.dark} />
            }
          >
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: Tokens.feedbackColor.error.dark,
              }}
            >
              Link inválido
            </Typography>
            <div style={{ marginTop: 5 }} />
            <Typography
              variant="body2"
              style={{ fontSize: 14, color: Tokens.feedbackColor.error.dark }}
            >
              Este link está inválido. Verifique se você abriu ou copiou o link
              correto.
            </Typography>
          </Alert>
        </WelcomeContainer>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <WelcomeContainer>
        {loading && !responseForm?.name ? (
          <div
            style={{
              flexGrow: 1,
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LocalLoading show />
          </div>
        ) : (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ color: Tokens.neutralColor.dark[100] }}
            >
              Oi, {responseForm?.name}
            </Typography>
            <div style={{ marginTop: 40 }} />
            <Typography
              variant="caption"
              gutterBottom
              style={{ color: Tokens.neutralColor.medium[400], fontSize: 14 }}
            >
              Processos em andamento
            </Typography>
            <div style={{ marginTop: 15 }} />
            <Card variant="outlined">
              <CardContent>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ color: Tokens.neutralColor.dark[100] }}
                >
                  Formulário de admissão
                </Typography>
                <div style={{ marginTop: 25 }} />
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <LinearProgress
                      className={
                        isFormStatusAwaitingApproval || isFormStatusFinished
                          ? classes.progress
                          : ""
                      }
                      style={{
                        borderRadius: 10,
                        height: 8,
                        backgroundColor: Tokens.neutralColor.light[800],
                      }}
                      variant="determinate"
                      value={percentComplete}
                    />
                  </Grid>
                  {!isFixForm && (
                    <Grid item>
                      <Grid container alignItems="center">
                        <Typography
                          variant="caption"
                          style={{
                            color: isComplete
                              ? Tokens.feedbackColor.success.pure
                              : Tokens.neutralColor.medium[400],
                          }}
                        >
                          {completedItems.length} /{" "}
                          {responseForm?.sections?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container alignItems="center">
                      {isFixForm ? (
                        <img src={FixFormIcon} alt={"Fix Form"} />
                      ) : isComplete ? (
                        <img src={SuccessCheckIcon} alt={"Success Check"} />
                      ) : (
                        <img
                          src={SuccessCheckWhiteIcon}
                          alt={"Success Check"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 25 }} />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <img src={CalendarIcon} alt={"Calendar"} />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="caption"
                      gutterBottom
                      style={{
                        color: Tokens.neutralColor.dark[200],
                        fontSize: 14,
                      }}
                    >
                      {responseForm?.admittanceDate
                        ? format(
                            new Date(
                              responseForm?.admittanceDate
                                .replace(/-/g, "/")
                                .replace(/T.+/, ""),
                            ),
                            "dd/MM/yyyy",
                          )
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
                {isFormStatusNotStarted ? (
                  <>
                    <div style={{ marginTop: 15 }} />
                    <Button
                      variant="contained"
                      onClick={() => responseForm?.sections && onFillForm()}
                      style={{ height: 50 }}
                      fullWidth
                      color="primary"
                    >
                      Preencher formulário
                    </Button>
                  </>
                ) : null}
                {isFormStatusInProgress ? (
                  <>
                    {isFixForm ? (
                      <>
                        <div style={{ marginTop: 15 }} />
                        <Chip
                          label="Ajuste solicitado"
                          size="medium"
                          style={{
                            backgroundColor: Tokens.feedbackColor.warning.light,
                            justifyContent: "flex-start",
                          }}
                        />
                      </>
                    ) : isApproximateDate ? (
                      <>
                        <div style={{ marginTop: 15 }} />
                        <Chip
                          label="Data de entrada se aproximando"
                          size="medium"
                          style={{
                            backgroundColor: Tokens.feedbackColor.warning.light,
                            justifyContent: "flex-start",
                          }}
                        />
                      </>
                    ) : null}
                    <div style={{ marginTop: 15 }} />
                    {isFixForm && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: 20,
                        }}
                      >
                        <div style={{ marginBottom: 20 }}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            style={{
                              color: Tokens.neutralColor.dark[300],
                              fontSize: 14,
                            }}
                          >
                            A empresa solicitou um ajuste.
                          </Typography>
                        </div>

                        <div
                          style={{
                            backgroundColor: Tokens.neutralColor.light[800],
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            gutterBottom
                            style={{
                              color: Tokens.neutralColor.dark[100],
                              fontSize: 14,
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {responseForm?.fixMessage}
                          </Typography>
                        </div>
                      </div>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => responseForm?.sections && onFillForm()}
                      style={{ height: 50 }}
                      fullWidth
                      color="primary"
                    >
                      {isFixForm
                        ? "Corrigir formulário"
                        : "Continuar formulário"}
                    </Button>
                  </>
                ) : null}
                {isFormStatusAwaitingApproval ? (
                  <>
                    <div style={{ marginTop: 15 }} />
                    <Chip
                      label="Aguardando aprovação"
                      color="primary"
                      size="medium"
                      style={{ justifyContent: "flex-start" }}
                    />
                    <div style={{ marginTop: 15 }} />
                    <Typography
                      variant="caption"
                      gutterBottom
                      style={{
                        color: Tokens.neutralColor.dark[200],
                        fontSize: 14,
                      }}
                    >
                      A empresa já recebeu seu formulário e agora está
                      conferindo as informações.
                    </Typography>
                  </>
                ) : null}
                {isFormStatusFinished ? (
                  <>
                    <div style={{ marginTop: 15 }} />
                    <Chip
                      label="Admissão concluída"
                      size="medium"
                      style={{
                        backgroundColor: Tokens.feedbackColor.success.light,
                        justifyContent: "flex-start",
                      }}
                    />
                  </>
                ) : null}
              </CardContent>
            </Card>
            <div style={{ marginTop: 45 }} />
            {responseForm?.welcomeMessage?.contact ? (
              <div>
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{
                    color: Tokens.neutralColor.medium["400"],
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Contato para dúvidas
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ paddingTop: 8 }}
                >
                  <Grid item>
                    <img src={EnvelopeIcon} alt={"Envelope"} />
                  </Grid>
                  <Grid item xs>
                    <Grid
                      container
                      spacing={1}
                      direction="column"
                      style={{ alignItems: "flexStart" }}
                    >
                      <Grid item xs>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ color: Tokens.interfaceColor.primary.pure }}
                        >
                          {responseForm?.welcomeMessage?.contact}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {responseForm?.welcomeMessage?.contactPhone ? (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <img src={CallIcon} alt={"Call"} />
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        spacing={1}
                        direction="column"
                        style={{ alignItems: "flexStart" }}
                      >
                        <Grid item xs>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              color: Tokens.interfaceColor.primary.pure,
                            }}
                          >
                            {responseForm?.welcomeMessage?.contactPhone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </WelcomeContainer>
    </div>
  );
}
